export const getSNItemsFormatted = (list) => {
  const itemsFormatted = [];
  list.forEach((ticket) => {
    let currReq;
    if (ticket.items.length > 1) {
      currReq = _handleReqsWithMultipleItems(
        ticket.items,
        ticket.requested_for
      );
      itemsFormatted.push(...currReq);
    } else {
      currReq = _createSNTicketObj(ticket.items[0], ticket.requested_for);
      itemsFormatted.push(currReq);
    }
  });
  return itemsFormatted;
};

export const handleTicketNameFormat = (orderGuideName, ticketName) => {
  return orderGuideName !== undefined
    ? `${orderGuideName} - ${ticketName}`
    : ticketName;
};

const _createSNTicketObj = (ticket, requestedFor) => {
  let item = {
    ticketId: ticket.request_item_id,
    name: ticket.cat_item_name,
    status: ticket.status,
    lastUpdate: ticket.last_updated,
    openedAt: ticket.opened_at,
    submittedDate: ticket.opened_at,
    closedAt: ticket.closed_at,
    type: ticket.type,
    priority: ticket.priority,
    person: requestedFor,
    source: 'sn'
  };
  if (ticket.isFromOrderGuide) {
    item.orderGuideName = ticket.orderGuideName;
  }
  return item;
};

const _handleReqsWithMultipleItems = (ritms, requestedFor) => {
  return ritms.map((item) => _createSNTicketObj(item, requestedFor));
};
